<template>
  <v-app id="my-app">
    <v-app-bar fixed class="my-app-bar" app>
      <v-app-bar-title
        ><v-btn
          text
          href="#"
          class="text-body-2 white--text font-weight-light text-lowercase hidden-sm-and-down"
          >Zsolt's profile</v-btn
        ></v-app-bar-title
      >
      <v-spacer></v-spacer>
      <v-btn
        v-for="(menuItem, i) in slider.menu"
        :key="i"
        :href="menuItem.link"
        text
      >
        <span class="font-weight-light text-lowercase white--text">
          {{ menuItem.title }}
        </span>
      </v-btn>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-main>
      <v-container :id="slider.id" fluid class="px-0">
        <v-row class="py-16">
          <v-col class="col-1 col-md-1"></v-col>
          <v-col class="col-10 col-md-10">
            <v-card class="transparent">
              <div class="d-flex flex-no-wrap justify-space-between">
                <div class="my-auto hidden-sm-and-down">
                  <v-card-title class="text-h2 white--text">{{
                    slider.hello
                  }}</v-card-title>
                  <v-card-subtitle
                    v-text="slider.whoami"
                    class="text-h5 amber--text text--lighten-2 font-weight-light"
                  ></v-card-subtitle>
                </div>
                <v-img
                  src="./assets/its_me.jpg"
                  max-width="290px"
                  class="py-3 hidden-sm-and-down"                  
                ></v-img>
                <v-img
                  src="./assets/its_me.jpg"
                  max-width="290px"                  
                  class="py-3 mx-auto hidden-md-and-up"                  
                ></v-img>
              </div>
            </v-card>
          </v-col>
          <v-col class="col-1 col-md-1"></v-col>
        </v-row>
      </v-container>
      <v-container :id="about.id" fluid>
        <v-row>
          <v-col class="col-1 col-md-1"><v-spacer></v-spacer></v-col>
          <v-col class="col-10 col-md-10 bg-dark">
            <v-card class="bg-dark">
              <v-subheader class="text-h4 bg-dark">
                <span class="text-h4 font-weight-light white--text bg-dark">
                  {{ about.header.title }}
                </span>
              </v-subheader>
              <v-card-subtitle class="pt-0"
                ><span
                  class="amber--text text--lighten-2 pt-0 font-weight-light"
                  >{{ about.header.quote.text }} -
                  <v-btn
                    text
                    plain
                    :href="about.header.quote.link"
                    target="_blank"
                    class="amber--text text--lighten-2 px-0 font-weight-light"
                    >//{{ about.header.quote.person }}//</v-btn
                  >
                </span></v-card-subtitle
              >
            </v-card>
          </v-col>
          <v-col class="col-1 col-md-1"><v-spacer></v-spacer></v-col>
        </v-row>
        <v-row class="pb-16">
          <v-col class="col-1 col-md-1"><v-spacer></v-spacer></v-col>
          <v-col class="col-10 col-md-10 bg-dark">
            <v-row class="hidden-sm-and-down">
              <v-col class="col-7 col-md-7">
                <p
                  id="about-text"
                  class="white--text font-weight-light pl-4 text-justify"
                >
                  {{ about.text }}
                </p>
              </v-col>
              <v-col class="col-1 col-md-1"></v-col>
              <v-col class="col-4 col-md-4">
                <v-card
                  v-for="(skill, i) in about.skills"
                  :key="i"
                  class="transparent pb-8 pr-8"
                >
                  <p class="text-body-1 font-weight-light white--text mb-0">
                    {{ skill.title }}
                  </p>
                  <v-progress-linear
                    :value="skill.strength"
                    color="amber lighten-2"
                    class="px-16"
                  ></v-progress-linear>
                </v-card>
              </v-col>
            </v-row>
            <v-row class="hidden-md-and-up px-8">
              <v-row>
                <p
                  id="about-text"
                  class="white--text font-weight-light text-justify"
                >
                  {{ about.text }}
                </p>
              </v-row>
              <v-row>
                <v-list class="transparent pb-10">
                  <v-list-item
                    v-for="(skill, i) in about.skills"
                    :key="i"
                    class="pb-4 px-0"
                  >
                    <v-card class="transparent">
                      <p
                        class="text-body-1 font-weight-light white--text mb-0 pr-16"
                      >
                        {{ skill.title }}
                      </p>
                      <v-progress-linear
                        :value="skill.strength"
                        color="amber lighten-2"
                        class="px-16"
                      ></v-progress-linear>
                    </v-card>
                  </v-list-item>
                </v-list>
              </v-row>
            </v-row>
          </v-col>
          <v-col class="col-1 col-md-1"><v-spacer></v-spacer></v-col>
        </v-row>
      </v-container>
      <v-container :id="work.id" transparent fluid>
        <v-row class="hidden-sm-and-down">
          <v-col class="col-1 col-md-1"><v-spacer></v-spacer></v-col>
          <v-col class="col-10 col-md-10">
            <v-card class="transparent">
              <v-subheader class="text-h4">
                <span class="text-h4 font-weight-light white--text">
                  {{ work.header.title }}
                </span>
              </v-subheader>
              <v-card-subtitle class="pt-0"
                ><span
                  class="amber--text text--lighten-2 pt-0 font-weight-light"
                  >{{ work.header.quote.text }} -
                  <v-btn
                    text
                    plain
                    :href="work.header.quote.link"
                    target="_blank"
                    class="amber--text text--lighten-2 px-0 font-weight-light"
                    >//{{ work.header.quote.person }}//</v-btn
                  ></span
                ></v-card-subtitle
              >
            </v-card>
          </v-col>
          <v-col class="col-1 col-md-1"><v-spacer></v-spacer></v-col>
        </v-row>
        <v-row class="hidden-md-and-up">
          <v-col class="col-1 col-md-1"><v-spacer></v-spacer></v-col>
          <v-col class="col-10 col-md-10">
            <v-card class="transparent">
              <v-subheader class="text-h4 px-0">
                <span class="text-h4 font-weight-light white--text">
                  {{ work.header.title }}
                </span>
              </v-subheader>
              <v-card-subtitle class="pt-0 px-0"
                ><span
                  class="amber--text text--lighten-2 pt-0 font-weight-light"
                  >{{ work.header.quote.text }} -
                  <v-btn
                    text
                    plain
                    :href="work.header.quote.link"
                    target="_blank"
                    class="amber--text text--lighten-2 px-0 font-weight-light"
                    >//{{ work.header.quote.person }}//</v-btn
                  ></span
                ></v-card-subtitle
              >
            </v-card>
          </v-col>
          <v-col class="col-1 col-md-1"><v-spacer></v-spacer></v-col>
        </v-row>
        <v-row class="hidden-sm-and-down">
          <v-col class="col-1 col-md-1"><v-spacer></v-spacer></v-col>
          <v-col class="col-10 col-md-10">
            <v-timeline>
              <v-timeline-item
                v-for="(job, i) in work.history"
                :key="i"
                :color="i == 0 ? 'amber darken-2' : 'grey'"
              >
                <template v-slot:opposite>
                  <v-container class="d-flex flex-column">
                    <span
                      :class="`headline font-weight-bold white--text ${
                        i % 2 == 0 ? 'ml-auto' : 'mr-auto'
                      }`"
                      v-text="
                        job.years.from == null
                          ? '- ' + job.years.to
                          : job.years.from + ' - ' + job.years.to
                      "
                    >
                    </span>
                    <v-img
                      :src="require(`./assets/${job.company.image}`)"
                      max-width="136px"
                      max-height="93px"
                      :class="`elevation-6 white ${
                        i % 2 == 0 ? 'ml-auto' : 'mr-auto'
                      }`"
                    ></v-img>
                  </v-container>
                </template>
                <v-card class="elevation-6">
                  <v-card-title class="bg-dark">
                    <h2 class="text-h6 white--text font-weight-light">
                      {{ job.position }}
                    </h2>
                  </v-card-title>
                  <v-container>
                    <v-row class="px-4 pt-2">
                      <v-col
                        cols="12"
                        md="12"
                        class="text-justify font-weight-light job-description"
                      >
                        {{ job.description }}
                      </v-col>
                    </v-row>
                  </v-container>
                  <v-item-group multiplie>
                    <v-subheader class="font-weight-bold px-2"
                      >Skills</v-subheader
                    >
                    <v-chip-group column class="mx-2">
                      <v-chip
                        v-for="(tag, i) in job.tags"
                        :key="i"
                        class="font-weight-light chip"
                        :color="
                          tag.skill == 'soft'
                            ? 'amber darken-1'
                            : 'grey darken-3'
                        "
                        outlined
                        disabled
                      >
                        {{ tag.name }}</v-chip
                      >
                    </v-chip-group>
                  </v-item-group>
                </v-card>
              </v-timeline-item>
            </v-timeline>
          </v-col>
          <v-col class="col-1 col-md-1"><v-spacer></v-spacer></v-col>
        </v-row>
        <v-row class="hidden-md-and-up pb-16">
          <v-col class="col-1 col-md-1"><v-spacer></v-spacer></v-col>
          <v-col class="col-10 col-md-10">
            <v-card
              v-for="(job, i) in work.history"
              :key="i"
              class="transparent"
            >
              <v-card-title class="px-0">
                <v-list class="transparent">
                  <v-list-item class="job-item-mobile px-0 pb-0">
                    <h2 class="text-body-1 white--text font-weight-bold">
                      {{ job.position }}
                    </h2>
                  </v-list-item>
                  <v-list-item class="px-0">
                    <span
                      class="text-body-1 amber--text lighten-2 font-weight-light"
                      >{{ job.company.name }} ({{ job.years.from }} -
                      {{ job.years.to }})
                    </span>
                  </v-list-item>
                </v-list>
              </v-card-title>
              <v-container class="px-0">
                <v-row >
                  <v-col
                    cols="12"
                    md="12"
                    class="text-justify font-weight-light job-description"
                  >
                    <span class="white--text">{{ job.description }}</span>
                  </v-col>
                </v-row>
              </v-container>
              <v-item-group multiplie>
                <v-subheader class="font-weight-bold white--text px-0"
                  >Skills</v-subheader
                >
                <v-chip-group column>
                  <v-chip
                    v-for="(tag, i) in job.tags"
                    :key="i"
                    class="font-weight-light chip"
                    :color="
                      tag.skill == 'soft' ? 'amber darken-1' : 'white darken-3'
                    "
                    outlined
                    ripple="false"
                    label
                    disabled
                  >
                    {{ tag.name }}</v-chip
                  >
                </v-chip-group>
              </v-item-group>
            </v-card>
          </v-col>
          <v-col class="col-1 col-md-1"><v-spacer></v-spacer></v-col>
        </v-row>
      </v-container>
      <v-container :id="hobbies.id" fluid>
        <v-row>
          <v-col class="col-1 col-md-1"><v-spacer></v-spacer></v-col>
          <v-col class="col-10 col-md-10 bg-dark hidden-sm-and-down">
            <v-card class="bg-dark">
              <v-subheader class="text-h4 bg-dark">
                <span class="text-h4 font-weight-light white--text bg-dark">
                  {{ hobbies.header.title }}
                </span>
              </v-subheader>
              <v-card-subtitle class="pt-0"
                ><span
                  class="amber--text text--lighten-2 pt-0 font-weight-light"
                  >{{ hobbies.header.quote.text }} -
                  <v-btn
                    text
                    plain
                    :href="hobbies.header.quote.link"
                    target="_blank"
                    class="amber--text text--lighten-2 px-0 font-weight-light"
                    >//{{ hobbies.header.quote.person }}//</v-btn
                  ></span
                ></v-card-subtitle
              >
            </v-card>
          </v-col>
          <v-col class="col-10 col-md-10 bg-dark hidden-md-and-up px-0">
            <v-card class="bg-dark">
              <v-subheader class="text-h4 bg-dark">
                <span class="text-h4 font-weight-light white--text bg-dark">
                  {{ hobbies.header.title }}
                </span>
              </v-subheader>
              <v-card-subtitle class="pt-0"
                ><span
                  class="amber--text text--lighten-2 pt-0 font-weight-light"
                  >{{ hobbies.header.quote.text }} -
                  <v-btn
                    text
                    plain
                    :href="hobbies.header.quote.link"
                    target="_blank"
                    class="amber--text text--lighten-2 px-0 font-weight-light"
                    >//{{ hobbies.header.quote.person }}//</v-btn
                  ></span
                ></v-card-subtitle
              >
            </v-card>
          </v-col>
          <v-col class="col-1 col-md-1"><v-spacer></v-spacer></v-col>
        </v-row>
        <v-row class="hidden-sm-and-down">
          <v-col class="col-1 col-md-1"><v-spacer></v-spacer></v-col>
          <v-col class="col-10 col-md-10 bg-dark">
            <p class="white--text font-weight-light px-4 text-justify">
              {{ hobbies.text }}
            </p>
          </v-col>
          <v-col class="col-1 col-md-1"><v-spacer></v-spacer></v-col>
        </v-row>
        <v-row class="hidden-md-and-up">
          <v-col class="col-1 col-md-1"><v-spacer></v-spacer></v-col>
          <v-col class="col-10 col-md-10 bg-dark">
            <p class="white--text font-weight-light text-justify">
              {{ hobbies.text }}
            </p>
          </v-col>
          <v-col class="col-1 col-md-1"><v-spacer></v-spacer></v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-footer class="transparent pt-8">
      <v-card flat tile width="100%" class="transparent text-center">
        <v-card-text>
          <v-btn class="mx-4" icon large href="#">
            <v-icon size="24px" color="white">mdi-home </v-icon>
          </v-btn>
          <v-btn
            class="mx-4"
            icon
            large
            href="https://www.linkedin.com/in/zsolt-schoner/"
            target="_blank"
          >
            <v-icon size="24px" color="white">mdi-linkedin</v-icon>
          </v-btn>
          <v-btn
            class="mx-4"
            icon
            large
            href="https://www.facebook.com/zsschoner"
            target="_blank"
          >
            <v-icon size="24px" color="white">mdi-facebook</v-icon>
          </v-btn>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text class="text--display-1 white--text font-weight-light">
          <div class="powered-by font-weight-bold">2021 — powered by</div>
          <v-btn
            text
            href="https://vuetifyjs.com/en/"
            target="_blank"
            class="footer-icon text--display-1 white--text font-weight-light"
            ><v-icon size="24px" color="white" class="mx-1">mdi-vuetify</v-icon
            >Vuetify,</v-btn
          >
          <v-btn
            text
            href="https://azure.microsoft.com/en-us/services/app-service/static/"
            target="_blank"
            class="footer-icon text--display-1 white--text font-weight-light ml-1"
          >
            <v-icon size="24px" color="white" class="mx-1"
              >mdi-microsoft-azure</v-icon
            >Azure Static WebApp,</v-btn
          ><v-btn
            text
            href="https://azure.microsoft.com/en-us/services/devops/#overview"
            target="_blank"
            class="footer-icon text--display-1 white--text font-weight-light ml-1"
          >
            <v-icon size="24px" color="white" class="mx-1"
              >mdi-microsoft-azure-devops</v-icon
            >Azure DevOps,</v-btn
          >
          <v-btn
            text
            href="https://github.com/"
            target="_blank"
            class="footer-icon text--display-1 white--text font-weight-light ml-1"
            ><v-icon size="24px" color="white" class="mx-1">mdi-github</v-icon
            >Github</v-btn
          >
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "App",

  components: {},
  data: () => ({
    slider: {
      id: "slider",
      hello: "Hello, I am Zsolt",
      whoami:
        "guide, husband, father, friend, out-of-the-box thinker, traveller, fisherman...",
      menu: [
        { title: "about", link: "#about" },
        { title: "experience", link: "#experience" },
        { title: "hobbies", link: "#hobbies" },
      ],
    },
    about: {
      id: "about",
      header: {
        title: "a b o u t",
        quote: {
          text: `Success is walking from failure to failure with no loss of enthusiasm.`,
          person: "Winston Churchill",
          link: "https://en.wikipedia.org/wiki/Winston_Churchill",
        },
      },
      text: `I am a Development Manager based on Budapest who loves software projects and simple but working solutions. 
            I also enjoy crafting and solving problems. During the past 20 years I worked in different roles in various sized 
            software projects and companies. On some project I worked in a one-man-show fashion while on another assignment 
            I lead team of 20+ engineers/leads on an international initiative. Worked with people from 10+ nations and 4 
            continents in various role from developer to senior leads. I like the agile way though I worked on big 
            traditional software projects too.`,
      skills: [
        { title: "Management", strength: 80 },
        { title: "Development", strength: 70 },
        { title: "Operation", strength: 70 },
      ],
    },
    work: {
      id: "experience",
      header: {
        title: "e x p e r i e n c e",
        quote: {
          text: `The only way to do great job is to love what you do.`,
          person: "Steve Jobs",
          link: "https://en.wikipedia.org/wiki/Steve_Jobs",
        },
      },
      history: [
        {
          id: 1,
          position: "Development Manager",
          company: {
            image: "dealogic.png",
            name: "Dealogic",
          },
          years: { from: 2019, to: 2021 },
          description: `At Dealogic I am working in an environment which is under transformation where the DevOps initiative has just been kicked
                     off. It is a strong traditional IT driven company where we transform a VM based Equity Capital workflow platform
                     to a cloud-based SaaS platform. Some of the challanges we need to face with: old-fashioned/obsolete codebase, hundreds
                     of installations without configuration source of truth, monolithic application design, production configuration issues, 
                     purely configured monitoring, limited human resource... We re-introduced testing, fixed the deployment process, introduced 
                     monitoring ended-up with less issues and need of troubleshooting. Despite the challanges the assignment is interesting and 
                     we could improve our platform.`,
          tags: [
            { name: "Resource Management", skill: "soft" },
            { name: ".Net 4+", skill: "hard" },
            { name: "MS SQL", skill: "hard" },
            { name: "Motivation", skill: "soft" },
            { name: "Visual Studio Code", skill: "hard" },
            { name: "Transformation", skill: "soft" },
            { name: "Microsoft Azure", skill: "hard" },
            { name: "Powershell", skill: "hard" },
            { name: "Git", skill: "hard" },
            { name: "Problem Solving", skill: "soft" },
            { name: "Azure DevOps", skill: "hard" },
            { name: "Legacy Code", skill: "hard" },
          ],
        },
        {
          id: 2,
          position: "Software Development Team Leader",
          company: {
            image: "agco.png",
            name: "AGCO",
          },
          years: { from: 2017, to: 2019 },
          description: `I joined to AGCO as development team leader to transfer projects from South-America to Budapest. In the 
                     meantime, I was selected as delivery lead of the digital transformation program based in Germany. My main
                     focus was to kick-off the international program from delivery perspective and the brand-new DevOps team in 
                     Budapest. I also organized the hand-over of the software deliverables created by the main contractor Infosys 
                     and also managed other subcontractors. I hired 20+ engineers and established the so-called DevOps organization
                     in the Budapest office. The truly cross-functional team consisted of developers, testers, business analysts and 
                     DevOps engineers.`,
          tags: [
            { name: "People Management", skill: "soft" },
            { name: "Build Automation", skill: "hard" },
            { name: "Project Management", skill: "soft" },
            { name: "Test Automation", skill: "hard" },
            { name: "SaFe Implementation", skill: "soft" },
            { name: "Cloud (AWS)", skill: "hard" },
            { name: "Digital Transformation", skill: "soft" },
            { name: "Azure DevOps", skill: "hard" },
            { name: "Traditional Software Development", skill: "soft" },
            { name: "CPQ", skill: "hard" },
            { name: "Contractor Management", skill: "soft" },
            { name: "E-Commerce", skill: "hard" },
            { name: "Working in 3 time-zones", skill: "soft" },
            { name: "Microsoft Teams", skill: "hard" },
            { name: "DevOps team", skill: "soft" },
            { name: "MuleSoft Api Management", skill: "soft" },
            { name: "Jira", skill: "hard" },
            { name: "Jenkins", skill: "hard" },
          ],
        },
        {
          id: 3,
          position: "Software Development Team Leader",
          company: {
            image: "epam.png",
            name: "EPAM",
          },
          years: { from: 2012, to: 2017 },
          description: `I started as developer on an international expert platform project based in Oslo. I was part of a team
                     of 14 and developed UI APIs for geology experts in C#/.Net for a WPF application monster. The project was 
                     quite big (1000+ devs) and contained code from 1998 till today. We used programming techniques like TDD, 
                     SOLID, E2E testing, build automation etc. to be able to deliver a high-quality product. As part of the digital
                     transformation, we started to move the application to Google Cloud. This meant that some parts were moved with
                     lift and shift approach and other parts were completely rewritten as cloud native application with Node.Js, 
                     Google App and Google Functions. I supervised team of 10 from resource management perspective.`,
          tags: [
            { name: "Resource Management", skill: "soft" },
            { name: ".Net 4+", skill: "hard" },
            { name: "Technical Interviewing", skill: "soft" },            
            { name: "WPF", skill: "hard" },
            { name: "WebAPI", skill: "hard" },
            { name: "WinForms", skill: "hard" },
            { name: "WCF", skill: "hard" },
            { name: "TDD", skill: "hard" },
            { name: "SOLID", skill: "hard" },
            { name: "Sonar", skill: "hard" },
            { name: "FxCop", skill: "hard" },
            { name: "Mentoring", skill: "soft" },
            { name: "Visual Studio 2012", skill: "hard" },
            { name: "Visual Studio 2015", skill: "hard" },
            { name: "Microsoft TFS", skill: "hard" },
            { name: "Teamcity", skill: "hard" },
            { name: "Perforce", skill: "hard" },
            { name: "Google Cloud", skill: "hard" },
            { name: "NodeJs", skill: "hard" },
            { name: "Git", skill: "hard" },
            { name: "Angular", skill: "hard" },
            { name: "REST", skill: "hard" },
            { name: "gRpc", skill: "hard" },
          ],
        },
        {
          id: 4,
          position: "Senior Software Developer",
          company: {
            image: "grape-solutions.png",
            name: "Grape Solutions",
          },
          years: { from: 2012, to: 2012 },
          description: `I joined to a development team of 10 which developed a flight booking website for a Hungary based aviation company.
                    The application was written in ASP.Net, used Windows remoting, WCF and it was a site customization on the top of the 
                    Navitaire platform. My main focus was the development of the flight change process. I also introduced the Coded UI tests
                    with the use of Microsoft Test Framework to run E2E tests.`,
          tags: [
            { name: "ASP .Net", skill: "hard" },
            { name: "Visual Studio 2010", skill: "hard" },
            { name: "WCF", skill: "hard" },
            { name: "SOAP-UI", skill: "hard" },
            { name: "Xslt", skill: "hard" },
            { name: "Test Automation", skill: "hard" },
            { name: "Microsoft Test Framework", skill: "hard" },
          ],
        },
        {
          id: 5,
          position: "Consultant",
          company: {
            image: "sei.png",
            name: "SEI Interconnect Products",
          },
          years: { from: 2003, to: 2011 },
          description: `At SEI I worked together with domain experts to build and support a production line controlling system. The system
                    was built with Delphi and SQL and was installed to every desktop in the site. It was a 3-tier application which used the 
                    DB for integration. Main functionalities were barcode printing, barcode scanning in work centers, statistics generation, 
                    and real-time monitoring of the production line. My main responsibilities were the design, development (in small team) 
                    and support of the system.`,
          tags: [
            { name: "Business Analysis", skill: "soft" },
            { name: "Application Design", skill: "hard" },
            { name: "Requirement Gathering", skill: "soft" },
            { name: "Coordination", skill: "soft" },            
            { name: "Proposal writing", skill: "hard" },
            { name: "Delphi", skill: "hard" },
            { name: "MySQL", skill: "hard" },
            { name: "Db design", skill: "hard" },
            { name: "Windows UI", skill: "hard" },
            { name: "Windows Server 2003, 2008", skill: "hard" },
            { name: "Visual Studio 2005, 2008", skill: "hard" },
            { name: "SVN", skill: "hard" },
            { name: ".Net 3", skill: "hard" },
            { name: "WCF", skill: "hard" },
            { name: "MSSQL Server 2008", skill: "hard" },
            { name: "Fedora", skill: "hard" },
            { name: "STunnel", skill: "hard" },
            { name: "SSH", skill: "hard" },
            { name: "Cisco VPN", skill: "hard" },
            { name: "HyperV", skill: "hard" },
            { name: "Windows Server 2008", skill: "hard" },
          ],
        },
        {
          id: 6,
          position: "Student",
          company: {
            image: "student.png",
            name: "Various companies",
          },
          years: { from: null, to: 2003 },
          description: `As a student I worked on different projects from Alarm Control System to various SMS sending applications. 
                    When I was at the polytechnic I was part of the school radio and earned money for new equipments in a PFP project
                    for the school radio. We could use the money to replace old equipments and we could improve the services of our 
                    studio significantly.`,
          tags: [
            { name: "Project Management", skill: "soft" },
            { name: "Delphi", skill: "hard" },
            { name: "PIC Microcontroller", skill: "hard" },
            { name: "MySQL", skill: "hard" },
            { name: "Windows", skill: "hard" },
            { name: "Barcode", skill: "hard" },
          ],
        },
      ],
    },
    hobbies: {
      id: "hobbies",
      header: {
        title: "h o b b i e s",
        quote: {
          text: `No man is really happy or safe without a hobby.`,
          person: "William Osler",
          link: "https://en.wikipedia.org/wiki/William_Osler",
        },
      },
      text: `I spend most of my time with my kids and my wife so I can say family is my current hobby. 
      I enjoy going outside, cycling, playing boardgames, learning and discovering the world together with my family.
      I have sevaral destinations in my pocket which I'd like to show to my kids so once kids will be 
      big enough and COVID-19 ends we will travel around the World. We have a leopard gecko, his name is Jeno Rejto 
      after the famous Hungarian writer. We need to grow grasshoppers for him at home as he prefers live-food. 
      If I have spare time I also like being together with my friends, fishing, reading, listening music, playing Lego, 
      building models, programming, solving puzzles, going to museum, learning about architecture, cooking... During my life
      I co-founded start-ups, built physical networks, built a house, won fishing competitions, was 2nd place in a
      computer competition, held radio broadcasting events, was organizer of many politechnic events etc. The list is still
      growing as there are so many interesting thing that people can get to know/can try.`,
    },
  }),
};
</script>

<style>
.my-app-bar {
  background-image: url("./assets/background.jpg");
  background-position: center center;
  background-repeat: no-repeat;

  font-family: "Roboto", sans-serif;
  font-weight: normal;
  font-style: normal;
}

#my-app {
  background: url("./assets/background.jpg") repeat-y center !important;

  font-family: "Roboto", sans-serif;
  font-weight: normal;
  font-style: normal;
}

.bg-dark {
  background: #343a40 !important;
  background-color: #343a40 !important;
}

.job-description {
  color: #343a40 !important;
}

.job-item-mobile {
  min-height: 0 !important;
  letter-spacing: 0.0892857143em !important;
}

.chip {
  opacity: 1 !important;
}

.v-slide-group__wrapper {
  touch-action: auto !important;
}

.theme--light.v-timeline::before {
  background: #9e9e9e !important;
}

#about-text {
  line-height: 28px;
}

.footer-icon {
  color: white !important;
  margin: 0px;
  padding-left: 0px !important;
  padding-right: 0px !important;
  text-transform: none !important;
  vertical-align: unset !important;
}

.powered-by {
  margin-top: 4px;
  margin-bottom: auto;
  padding: 0px;
  height: 36px;
  display: inline-flex;
  vertical-align: middle !important;
  letter-spacing: 0.0892857143em;
}
</style>
